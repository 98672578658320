











































































import { localdata } from '@/helpers/local-data'
import web3 from 'web3'
import { walletStore } from '@/stores/wallet-store'
import { PublicKey } from '@solana/web3.js'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { KycViewModel } from '../viewmodels/kyc-viewmodel'

@Observer
@Component({
  components: {},
})
export default class KycFeeDialog extends Vue {
  walletStore = walletStore
  @Inject() vm!: KycViewModel
}
